<section>
  <div class="header-container">
    <h3>
      <mat-icon (click)="close()" class="back-btn" matTooltip="Volver atras">arrow_back</mat-icon>Completar datos del
      prospecto
    </h3>
    <div class="button-wrap">
      <button mat-button class="success" (click)="submit()"><mat-icon>save</mat-icon> Guardar</button>
    </div>
  </div>
  <div class="card">
    <div class="row prospect-info">
      <div class="col-md-2">
        <label>Nombre comercial</label>
        <p>{{this.prospectData.nombre}}</p>
      </div>
      <div class="col-md-2">
        <label>Nombre del cliente</label>
        <p>{{this.prospectData.nombreCliente}}</p>
      </div>
      <div class="col-md-2">
        <label>Sexo</label>
        <p>{{prospectData.gender == "female" ? 'Femenino': 'Masculino'}}</p>
      </div>
      <div class="col-md-2">
        <label>Edad</label>
        <p>{{this.prospectData.age}}</p>
      </div>
      <div class="col-md-2">
        <label for="">Número de teléfono</label>
        <p>{{this.prospectData.telefono}}</p>
      </div>
      <div class="col-md-2">
        <label for="">Correo electrónico</label>
        <p>{{this.prospectData.email}}</p>
        <span *ngIf="prospectData.email == ''" class="requiered-field">*Se requiere este campo antes de continuar
        </span>
      </div>
    </div>

    <div class="row prospect-info">
      <div class="col-md-2">
        <label for="">Giro comercial</label>
        <p>{{this.prospectData.giroComercial}}</p>
        <span *ngIf="prospectData.giroComercial == ''" class="requiered-field">*Se requiere este campo antes de
          continuar </span>
      </div>
      <div class="col-md-2">
        <label for="">Dirección comercial</label>
        <p>{{this.prospectData.direccion}}</p>
      </div>
      <div class="col-md-2">
        <label for="">Dirección fiscal</label>
        <p>{{this.prospectData.fiscalAddress}}</p>
      </div>
      <div class="col-md-2">
        <label for="">Ciudad</label>
        <p>{{this.prospectData.city}}</p>
      </div>
      <div class="col-md-2">
        <label for="">Origen</label>
        <p>{{this.prospectData.origin}}</p>
      </div>
      <div class="col-md-2">
        <label for="">Fecha de capacitación</label>
        <p>{{this.prospectData.trainingDate | date:'dd MMMM yyyy' }}</p>
      </div>
    </div>

    <div class="row prospect-info">
      <div class="col-md-2">
        <label for="">Ejecutivo asignado</label>
        <p>{{this.prospectData.vendor}}</p>
      </div>
      <div class="col-md-2">
        <label for="">Plan contratado</label>
        <p>{{this.prospectData.plan}}</p>
        <span *ngIf="prospectData.plan == ''" class="requiered-field">*Se requiere este campo antes de continuar
        </span>
      </div>
      <div class="col-md-2">
        <label for="">Campaña</label>
        <p>{{this.prospectData.campaign}}</p>
      </div>
      <div class="col-md-2">
        <label for="">Promoción aplicada</label>
        <p>{{this.prospectData.promotion}}</p>
      </div>
      <div class="col-md-2">
        <label for="">Requiere Factura</label>
        <p>{{this.prospectData.invoice}}</p>
      </div>
    </div>
  </div>

  <form [formGroup]="formTwo">
    <div class="section">
      <div class="header-section">
        <h4>Información de contacto</h4>
        <hr>
      </div>
      <div class="form-group row">
        <input hidden type="file" #imgFileInput multiple placeholder="Upload file" accept=".png,.jpg,.jpeg" name="file"
          (change)="saveFile($event)" required />
        <div class="col-md-6" style="text-align: center;">
          <img class="imgPreview" [src]="urlPreviweImg || urlDefaultImg" alt="log image" />
          <button type="button" nbButton ghost size="tiny" class="filebutton" style="color: #222b45;"
            (click)="imgFileInput.click()">
            <mat-icon>camera_alt</mat-icon> Cambiar logo
          </button>
          <div class="alert alert-danger" *ngIf="logoError">
            <span>Un logo es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label>Numero asignado</label>
          <input type="text" (input)="onlyNums($event)" maxlength="10" formControlName="phone"
            placeholder="Numero asignado al cliente" class="mb-3">
          <div class="alert alert-danger" *ngIf="fieldRequired('phone')">
            <span>El campo Numero asignado es requerido</span>
          </div>

          <label>Redes sociales</label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="socialMedia"
            placeholder="Redes sociales">
          <div class="alert alert-danger" *ngIf="fieldRequired('socialMedia')">
            <span>El campo redes sociales es requerido</span>
          </div>
        </div>

      </div>
    </div>
    <div class="section">
      <div class="header-section">
        <h4>Información de actividades</h4>
        <hr>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label>Líder de equipo</label>
          <mat-select multiple fullWidth placeholder="Lider de equipo" formControlName="teamLeader">
            <mat-option *ngFor="let item of agentList" [value]="item.nombre">{{item.nombre | titlecase}}
            </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('teamLeader')">
            <span>El campo líder de equipo es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Asistente Back Up</label>
          <mat-select multiple fullWidth placeholder="Asistente Back Up" formControlName="assistant">
            <mat-option *ngFor="let item of agentList" [value]="item.nombre">{{item.nombre | titlecase}}
            </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('assistant') ">
            <span>El campo asistente de back up es requerido</span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Tareas recurrentes</label>
          <mat-select multiple placeholder="Tareas recurrentes" formControlName="tareasRecurrentes" fullWidth>
            <mat-option [value]="task" *ngFor="let task of tasks">{{task.title}} </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('tareasRecurrentes')">
            <span>El campo tareas recurrentes es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label>Supervisora</label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="supervisor"
            placeholder="Supervisora">
          <div class="alert alert-danger" *ngIf="fieldRequired('supervisor')">
            <span>El campo supervisor es requerido</span>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Prefijo</label>
          <input type="tel" matInput fullWidth fieldSize="medium" formControlName="prefix" placeholder="Prefijo"
            (input)="onlyNums($event)" class="mb-3">
          <div class="alert alert-danger" *ngIf="fieldRequired('prefix')">
            <span>El campo prefijo es requerido</span>
          </div>
          <label>Especialidad</label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="specialty"
            placeholder="Especialidad">
          <div class="alert alert-danger" *ngIf="fieldRequired('specialty')">
            <span>El campo especilidad es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Horario de atención</label>
          <div class=" form-group row">
            <div class="col-md-6">
              <label style="border-radius: 0px;">De las</label>
              <ngb-timepicker formControlName="startHourWork" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
            </div>
            <div class="col-md-6">
              <label style="border-radius: 0px;">A las</label>
              <ngb-timepicker formControlName="endHourWork" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
            </div>
          </div>
          <div class="alert alert-danger"
            *ngIf="(this.formTwo.controls['startHourWork'].hasError('required') || this.formTwo.controls['endHourWork'].hasError('required'))  && this.showError">
            <span>Son requeridos ambos campos</span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <label>Etiqueta telefónica</label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="tag"
            placeholder="Etiqueta telefónica" [ngClass]="fieldRequired('tag') ? '': 'mb-3'">
          <div class="alert alert-danger" *ngIf="fieldRequired('tag')">
            <span>El campo etiqueta teléfonica es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label>Link de acceso a Drive</label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="drive"
            placeholder="Link de acceso a Drive">
          <div class="alert alert-danger" *ngIf="fieldRequired('drive')">
            <span>El campo link de acceso a drive es requerido</span>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="header-section">
        <h4>Información de contrato</h4>
        <hr>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Monto a facturar</label>
          <input type="number" (keydown)="onKeyDown($event)" matInput fullWidth fieldSize="medium"
            formControlName="inovoicePrice" placeholder="Monto a facturar">
          <div class="alert alert-danger" *ngIf="fieldRequired('inovoicePrice')">
            <span>El campo monto a facturar es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Monto facturado</label>
          <input type="number" (keydown)="onKeyDown($event)" matInput fullWidth fieldSize="medium"
            formControlName="invoicedAmount" placeholder="Monto facturado">
          <div class="alert alert-danger" *ngIf="fieldRequired('invoicedAmount')">
            <span>El campo monto facturado es requerido</span>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Fecha inicio de plan</label>
          <input matInput [matDatepicker]="picker" placeholder="Seleccione una fecha" formControlName="contratoDate"
            (click)="picker.open()">
          <mat-datepicker #picker></mat-datepicker>
          <div class="alert alert-danger" *ngIf="fieldRequired('contratoDate')">
            <span>El campo fecha inicio de plan es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Adjuntar contrato</label>
          <ng-container *ngIf="contractFile == undefined || contractFile == '' ">
            <input type="file" name="" (change)="saveContract($event,1)" class="fileContract"
              accept=".pdf, .png, .jpeg, .docx, .xls">
            <span *ngIf="this.showError" class="error-field">*El archivo de contrato está vacio</span>
          </ng-container>
          <ng-container *ngIf="contractFile != '' && contractFile != undefined">
            <button mat-button class="input-btn" (click)="deleteFile(1)"> <mat-icon>delete</mat-icon> Borrar
              archivo
              cargado
              previamente</button>
          </ng-container>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Adjuntar factura</label>
          <ng-container *ngIf="fileInvoice == undefined || fileInvoice == '' ">
            <input type="file" name="" (change)="saveContract($event,3)" class="dropFile"
              accept=".pdf, .png, .jpeg, .docx, .xls">
            <span *ngIf="this.showError" class="error-field">*El archivo de la factura está vacio</span>
          </ng-container>
          <ng-container *ngIf="fileInvoice != '' && fileInvoice != undefined ">
            <div class="dropzone-custom">
              <button mat-button class="input-btn" (click)="deleteFile(3)"> <mat-icon>delete</mat-icon> Borrar
                archivo
                cargado
                previamente</button>
            </div>
          </ng-container>

          <label for="">Adjuntar Comprobante de pago</label>
          <ng-container *ngIf="fileFiscal == undefined || fileFiscal == '' ">
            <input type="file" name="" (change)="saveContract($event,4)" class="dropFile"
              accept=".pdf, .png, .jpeg, .docx, .xls">
            <span *ngIf="this.showError" class="error-field">*El archivo de Comprobante de pago está vacio</span>
          </ng-container>
          <ng-container *ngIf="fileFiscal != '' && fileFiscal != undefined ">
            <div class="dropzone-custom">
              <button mat-button class="input-btn" (click)="deleteFile(4)"> <mat-icon>delete</mat-icon> Borrar
                archivo
                cargado
                previamente</button>
            </div>
          </ng-container>
        </div>
        <div class="col-md-6">
          <label for="">Adjuntar convenio de confidencialidad</label>
          <ng-container *ngIf="fileAgreement == undefined || fileAgreement == '' ">
            <input type="file" name="" (change)="saveContract($event,2)" class="dropFile"
              accept=".pdf, .png, .jpeg, .docx, .xls">
            <span *ngIf="this.showError" class="error-field">*El archivo del convenio está vacio</span>
          </ng-container>
          <ng-container *ngIf="fileAgreement != '' && fileAgreement != undefined ">
            <div class="dropzone-custom">
              <button mat-button class="input-btn" (click)="deleteFile(2)"> <mat-icon>delete</mat-icon> Borrar
                archivo
                cargado
                previamente</button>
            </div>
          </ng-container>

          <label for="">Cedula fiscal de pago</label>
          <ng-container *ngIf="filePay == undefined || filePay== '' ">
            <input type="file" name="" (change)="saveContract($event,5)" class="dropFile"
              accept=".pdf, .png, .jpeg, .docx, .xls">
            <span *ngIf="this.showError" class="error-field">*El archivo de Cedula fisca está vacio</span>
          </ng-container>
          <ng-container *ngIf="filePay != '' && filePay != undefined ">
            <div class="dropzone-custom">
              <button mat-button class="input-btn" (click)="deleteFile(5)"> <mat-icon>delete</mat-icon> Borrar
                archivo
                cargado
                previamente</button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</section>