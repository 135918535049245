import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { AngularFireStorage } from "@angular/fire/storage";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMinutesService } from "src/app/services/service-minutes/service-minutes.service";
import { Task } from '../../../models/task';
import { UserData } from "src/app/models/user";
import { ProspectsService } from "src/app/services/prospects/prospects.service";
import { UserService } from "src/app/services/users/user.service";
import { AuthService } from "src/app/services/authFirebase/auth.service";
import { PlanService } from "src/app/services/planService/plan.service";
import { Plan } from "src/app/models/plan";
import { FileProspect } from "src/app/models/prospects";

@Component({
  selector: 'app-complete-prospects',
  templateUrl: './complete-prospects.component.html',
  styleUrls: ['./complete-prospects.component.scss']
})
export class CompleteProspectsComponent implements OnInit {

  [x: string]: any;
  public formTwo: FormGroup;
  public users: UserData[] = [];
  public tasks: Task[] = []
  file: any = [];
  urlPreviweImg: any;
  showContract = false;
  showPage = false;
  showEdits = false;
  nombreE: string;
  urlDefaultImg = "https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/logo-default.png?alt=media&token=31c837f0-867c-431f-8778-4b45ba65f926";
  public planClient = "";
  config1: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    acceptedFiles: ".pdf, .jpg, .jpeg, .png",
  };
  public prospectData: any;
  public showError: boolean = false;
  public logoError: boolean = false;
  public contractFile: FileProspect | string
  public fileFiscal: FileProspect | string
  public fileInvoice: FileProspect | string
  public filePay: FileProspect | string
  public fileAgreement: FileProspect | string
  public userInfo: UserData
  public agentList: UserData[]
  public planList: Plan[]

  constructor(
    public formBuilder: FormBuilder,
    private storage: AngularFireStorage,
    private router: Router,
    private taskService: ServiceMinutesService,
    private activeRoute: ActivatedRoute,
    private prospectService: ProspectsService,
    private agentService: UserService,
    private authService: AuthService,
    private planService: PlanService
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.prospectData = []
  }

  async ngOnInit() {
    this.formTwo = this.formBuilder.group({
      tag: ["", [Validators.required,]],
      specialty: ["", [Validators.required,]],
      supervisor: ['', [Validators.required,]],
      teamLeader: [[], [Validators.required,]],
      assistant: [[], [Validators.required,]],
      tareasRecurrentes: [[], [Validators.required,]],
      startHourWork: [{ hour: 9, minute: 0 }, [Validators.required,]],
      endHourWork: [{ hour: 18, minute: 0 }, [Validators.required,]],
      drive: ["", [Validators.required,]],
      inovoicePrice: ["", [Validators.required,]],
      phone: ["", Validators.required],
      contratoDate: ["", Validators.required],
      invoicedAmount: ["", [Validators.required,]],
      socialMedia: ["", [Validators.required,]],
      prefix: ["", [Validators.required,]],
    });
    let param = this.activeRoute.snapshot.params['id']
    this.prospectData = await this.getProspectInfo(param);
    this.tasks = await this.getTasks()
    this.userInfo = await this.getActiveUser()
    this.agentList = await this.getAllAgents();
    this.planList = await this.getPLans()

    if (this.prospectData.plan != "") {
      this.formTwo.patchValue({ invoicedAmount: this.planList.filter((planInfo) => planInfo.title == this.prospectData.plan)[0].precio, inovoicePrice: this.planList.filter((planInfo) => planInfo.title == this.prospectData.plan)[0].precio })
    }
    if (this.prospectData.fileAgreement != undefined && this.prospectData.fileAgreement != "") {
      this.fileAgreement = this.prospectData.fileAgreement
    }
    if (this.prospectData.fileContract != undefined && this.prospectData.fileContract != "") {
      this.contractFile = this.prospectData.fileContract
    }
    if (this.prospectData.fileInvoice != undefined && this.prospectData.fileInvoice != "") {
      this.fileInvoice = this.prospectData.fileInvoice
    }
    if (this.prospectData.fileFiscal != undefined && this.prospectData.fileFiscal != "") {
      this.fileFiscal = this.prospectData.fileFiscal
    }
    if (this.prospectData.filePay != undefined && this.prospectData.filePay != "") {
      this.filePay = this.prospectData.filePay
    }
  }

  async getActiveUser() {
    try {
      let currentUserId = this.authService.currentuser().uid;
      const currentUser = await this.agentService.getUserById(currentUserId);
      return currentUser
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los datos del usuario',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getAllAgents() {
    try {
      const response = await this.agentService.getAllUsers()
      if (response.code == 200) {
        return response.data
      }
      else {
        return []
      }
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los datos de los agentes',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getProspectInfo(prospectId: string) {
    try {
      var response = await this.prospectService.getProspectById(prospectId);
      return response
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar la información del prospecto',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  async getPLans() {
    try {
      const response = await this.planService.getPlans()
      if (response.code == 200) {
        return response.data
      }
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar la información de los planes',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  async getTasks() {
    try {
      return await this.taskService.getTask()
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar la tareas',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  deleteFile(type: number) {
    switch (type) {
      case 1:
        this.contractFile = undefined;
        break;
      case 2:
        this.fileAgreement = undefined
        break;
      case 3:
        this.fileInvoice = undefined
        break;
      case 4:
        this.fileFiscal = undefined
        break;
      case 5:
        this.filePay = undefined
        break;
    }
  }

  async saveFile(img) {
    this.file = img.target.files[0];
    const reader = new FileReader();
    const id = Math.random().toString(36).substring(2);
    const filePath = `companies/ProfilePhotos/${id}`;
    const fileRef = this.storage.ref(filePath);
    await this.storage.upload(filePath, this.file).snapshotChanges().toPromise();
    var downloadURL = await fileRef.getDownloadURL().toPromise();
    reader.onload = (e) => (this.urlPreviweImg = downloadURL);
    reader.readAsDataURL(this.file);
    this.file = downloadURL;
  }

  async saveContract(event, type) {
    var file = event.target.files[0];
    var split = event.target.files[0].name.split(".");
    var extension = split[split.length - 1];
    const id = Math.random().toString(36).substring(2);
    const filePath = `prospects/contracts/${id}`;
    const fileRef = this.storage.ref(filePath);
    await this.storage.upload(filePath, file).snapshotChanges().toPromise();
    var downloadURL = await fileRef.getDownloadURL().toPromise();
    switch (type) {
      case 1:
        this.contractFile = { url: downloadURL, extension: extension };
        break;
      case 2:
        this.fileAgreement = { url: downloadURL, extension: extension };
        break;
      case 3:
        this.fileInvoice = { url: downloadURL, extension: extension };
        break;
      case 4:
        this.fileFiscal = { url: downloadURL, extension: extension };
        break;
      case 5:
        this.filePay = { url: downloadURL, extension: extension };
        break;
      default:
        break;
    }
  }

  async submit() {
    var form = this.formTwo.value;

    form.profileImg = this.file
    form.fileContract = this.contractFile
    form.fileFiscal = this.fileFiscal
    form.fileInvoice = this.fileInvoice
    form.fileAgreement = this.fileAgreement
    form.filePay = this.filePay

    if (this.prospectData.plan == "" || this.prospectData.giroComercial == "" || this.prospectData.email == "") {
      return Swal.fire({
        title: "Campos faltantes del primer formulario para la creación de este cliente",
        text: "Se requiere los campos plan, Giro comercial y email. Revise que los campos esten completos antes de proceder :) ",
        icon: "error",
        confirmButtonText: "Entendido"
      });
    }

    if (this.file.length == 0) {
      this.logoError = true
    }
    if (this.formTwo.invalid) {
      this.formTwo.markAllAsTouched()
      // this.showError = true;
      return Swal.fire({
        title: "Todos los campos son obligatorios",
        text: "Favor de llenar los campos faltantes",
        icon: "error",
        confirmButtonText: "Entendido"
      });
    }
    Swal.fire({
      title: "Guardando",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    try {
      await this.prospectService.completeProspect(this.prospectData._id, form)
      Swal.close();
      await Swal.fire({
        title: "Nueva informacion agregada",
        icon: "success",
        timer: 1500,
        showConfirmButton: false
      });
      this.close();
    } catch (error) {
      console.log(error);
      if (error.error.code == "auth/email-already-exists") {
        return await Swal.fire({
          title: "El correo del prospecto ya esta usado para otro cliente",
          icon: "error",
          text: "Favor de cambiar el correo actual o verificar bien la información"
        });

      }
      await Swal.fire({
        title: "Ocurrió un error al actualizar los datos del prospecto",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
    return
  }

  close() {
    if (this.userInfo.tipo == 'administrador') {
      this.router.navigate(['marketing'])
    }
    else {
      this.router.navigate(['dashboard'])
    }
  }

  onlyNums(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/\D/g, '')
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'e' || event.key === '+' || event.key === '-' || event.key === '.') {
      event.preventDefault();
    }
  }

  // Validaciones
  fieldRequired(field: string) {
    return this.formTwo.controls[field]?.touched && this.formTwo.controls[field]?.invalid || (this.showError && this.formTwo.get(field)?.untouched)
  }
  fieldMin(field: string) {
    return this.formTwo.controls[field].hasError('min') && this.formTwo.controls[field].touched
  }
  fieldMinLength(field: string) {
    return this.formTwo.controls[field].hasError('minlength') && this.formTwo.controls[field].touched
  }
  fieldPattern(field: string) {
    return this.formTwo.controls[field].hasError('pattern') && this.formTwo.controls[field].touched
  }

}

